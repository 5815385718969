@use "sass:meta";
@tailwind base;
@tailwind components;
@tailwind utilities;

@include meta.load-css("fontawesome/fontawesome");
@include meta.load-css("fontawesome/solid");
@include meta.load-css("fontawesome/brands");
@include meta.load-css("fontawesome/regular");

@include meta.load-css("content.scss");

@font-face {
  font-family: "Horus";
  src: url("../fonts/horus.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Jaguar";
  src: url("../fonts/jaguar.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

// rlyehian
@font-face {
  font-family: "Rlyehian";
  src: url("../fonts/rlyehian.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* 🌟 Floating image effect (drop-cap style) */
.featured-float-image {
  float: left;
  width: 180px;
  max-width: 35%;
  height: auto;
  margin: 0 1rem 1rem 0 !important;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  shape-outside: margin-box;
}

/* 🪄 Ensure responsive behavior */
@media (max-width: 768px) {
  .featured-float-image {
    width: 100%;
    max-width: 100%;
    float: none;
    margin: 0 auto 1rem;
    display: block;
  }
}

/* ✨ Adjust first paragraph flow for emphasis */
.post-intro p:first-of-type {
  margin-top: 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: justify;
}
