.content {
    // Here is all styling for the content sections
    // Most of it is AI generated for occasions where 
    // you'll try to use some of that markdown stuff
    // Feel free to modify it to your liking

    *:not(li):not(ul) {
        @apply my-6;
    }

    * {
        @apply text-lg;
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
    }

    h3 {
        @apply text-2xl;
    }

    h4 {
        @apply text-xl;
    }

    h5 {
        @apply text-lg;
    }

    h6 {
        @apply text-base;
    }

    p {
        @apply text-base;
    }

    a {
        @apply text-primary-400 text-base font-bold;
    }

    a:hover {
        @apply text-primary-600;
    }

    ul {
        @apply list-disc;
    }

    ol {
        @apply list-decimal;
    }

    li {
        @apply ml-4;
    }

    blockquote {
        @apply border-l-4 border-gray-300 pl-4;
    }

    * img {
        @apply mx-auto w-8/12;
    }

    // Custom link styling for images ------------------

    * img[src*="ra_resize=1"] {
        @apply w-1/12;
    }

    * img[src*="ra_resize=2"] {
        @apply w-2/12;
    }

    * img[src*="ra_resize=3"] {
        @apply w-3/12;
    }

    * img[src*="ra_resize=4"] {
        @apply w-4/12;
    }

    * img[src*="ra_resize=5"] {
        @apply w-5/12;
    }

    * img[src*="ra_resize=6"] {
        @apply w-6/12;
    }

    * img[src*="ra_resize=7"] {
        @apply w-7/12;
    }

    * img[src*="ra_resize=8"] {
        @apply w-8/12;
    }

    * img[src*="ra_resize=9"] {
        @apply w-9/12;
    }

    * img[src*="ra_resize=10"] {
        @apply w-10/12;
    }

    * img[src*="ra_resize=11"] {
        @apply w-11/12;
    }

    * img[src*="ra_resize=12"] {
        @apply w-full;
    }

    * img[src*="ra_align=left"] {
        @apply ml-0;
    }

    * img[src*="ra_align=right"] {
        @apply mr-0;
    }

    // --------------------------------------------

    table {
        @apply w-full;
    }

    table th {
        @apply bg-gray-200;
    }

    table td {
        @apply border border-gray-300;
    }

    table th,
    table td {
        @apply p-2;
    }

    table tr:nth-child(even) {
        @apply bg-gray-100;
    }

    table tr:hover {
        @apply bg-gray-300;
    }

    pre {
        @apply bg-gray-200 p-4;
    }

    hr {
        @apply my-8 border-t-2 border-gray-300;
    }
}